.team-game {
  width: 100%;
}
.team-game__city-logo {
  width: 72px;
  height: 72px;
}
.team-game__author {
  color: #878d9a;
}
.team-game__flag {
  width: 160px;
  height: 85px;
}
.team-game__player-logo {
  width: 64px;
  height: 64px;
}
.team-game__table {
  width: fit-content;
  margin: 0 auto;
}
.team-game__black-stone {
  font-size: 1.5rem;
}
