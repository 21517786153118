@import '../../../css/colors';
@import '../../../css/sizes';

.team-game {

  width: 100%;

  &__city-logo {
    width: 72px;
    height: 72px;
  }

  &__author {color: darken(@grey, 30%)}

  &__flag {
    width: 160px;
    height: 85px;
  }

  &__player-logo {
    width: 64px;
    height: 64px;
  }

  &__table {
    width: fit-content;
    margin: 0 auto;
  }

  &__black-stone {font-size: 1.5rem}
}