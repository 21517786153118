@import '../../css/sizes';

.footer {
  display: flex;
  justify-content: space-between;

  margin-top: auto;

  &__logo {width: 100px}

  @media screen and (max-width: @mobile-width) {
    flex-direction: column;
    gap: @block-items-gap;
    align-items: center;
  }
}