.book {
  display: flex;
}
.book__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
}
.book__item:first-child {
  width: 35%;
}
.book__item:last-child {
  width: 70%;
  padding-top: 48px;
}
.book__img {
  width: 100%;
}
.book__author {
  font-weight: bold;
}
@media screen and (max-width: 430px) {
  .book {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .book__item:first-child {
    width: 100%;
  }
  .book__item:last-child {
    width: 100%;
    padding-top: 0;
  }
}
