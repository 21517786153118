.table {
  min-width: 80%;
}
.table_blue,
.table_blue tr,
.table_blue th,
.table_blue td {
  border: solid 1px #3fb2e6;
}
.table_blue tbody tr:nth-child(2n + 1) {
  background-color: #f3fafe;
}
.table_green,
.table_green tr,
.table_green th,
.table_green td {
  border: solid 1px #3aaa35;
}
.table_green tbody tr:nth-child(2n + 1) {
  background-color: #c2ebc0;
}
.table_red,
.table_red tr,
.table_red th,
.table_red td {
  border: solid 1px #e41f1e;
}
.table_red tbody tr:nth-child(2n + 1) {
  background-color: #fad4d4;
}
.table_yellow,
.table_yellow tr,
.table_yellow th,
.table_yellow td {
  border: solid 1px #ffde00;
}
.table_yellow tbody tr:nth-child(2n + 1) {
  background-color: #fff8cc;
}
.table_size-50 {
  min-width: 50%;
}
.table th {
  font-weight: 600;
}
.table,
.table tr,
.table th,
.table td {
  border: solid 1px #dadce0;
  border-collapse: collapse;
  border-spacing: 0;
}
.table tbody tr:nth-child(2n + 1) {
  background-color: #fbfcfc;
}
.table__cell {
  padding: 8px 16px;
}
.table__cell_left {
  padding-left: 16px;
  text-align: left;
}
.table__cell_green {
  background-color: #3aaa35;
  color: white;
}
.table__cell_red {
  background-color: #e41f1e;
  color: white;
}
.table__caption {
  padding-bottom: 16px;
  font-weight: 600;
}
@media screen and (max-width: calc(3 * 360px + 2 * 20px)) {
  .table {
    min-width: fit-content;
  }
}
@media screen and (max-width: 430px) {
  .table {
    width: 80%;
    border: none;
  }
  .table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 0px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0px;
  }
  .table tr {
    display: block;
    border: none;
    margin-bottom: 16px;
  }
  .table td {
    display: block;
    text-align: right;
    padding-right: 16px;
    border: none;
    border-bottom: solid 1px #dadce0;
  }
  .table__cell {
    padding: 0;
  }
  .table__cell_left {
    padding-left: 0;
  }
  .table td::before {
    content: attr(data-label);
    float: left;
    padding-left: 16px;
  }
  .table .country {
    padding: 0;
    margin-left: auto;
  }
  .table .country__empty-text::after {
    content: '.';
    margin-right: -12px;
    color: white;
  }
  .table .flex-row {
    width: fit-content;
    margin-left: auto;
  }
  .table .flex-row .country {
    margin-left: 8px;
  }
  .table td:last-child {
    border-bottom: 0;
  }
}
