/*
  Фон "Волна"
*/
.bg-wave-blue {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-blue::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3fb2e6;
  transition: all 0.5s linear;
}
.bg-wave-blue:hover {
  color: white;
  background: rgba(63, 178, 230, 0.75);
}
.bg-wave-blue:hover::after {
  left: -50%;
}
.bg-wave-green {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-green::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3aaa35;
  transition: all 0.5s linear;
}
.bg-wave-green:hover {
  color: white;
  background: rgba(58, 170, 53, 0.75);
}
.bg-wave-green:hover::after {
  left: -50%;
}
.bg-wave-red {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-red::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #e41f1e;
  transition: all 0.5s linear;
}
.bg-wave-red:hover {
  color: white;
  background: rgba(228, 31, 30, 0.75);
}
.bg-wave-red:hover::after {
  left: -50%;
}
.bg-wave-yellow {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-yellow::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #ffde00;
  transition: all 0.5s linear;
}
.bg-wave-yellow:hover {
  color: white;
  background: rgba(255, 222, 0, 0.75);
}
.bg-wave-yellow:hover::after {
  left: -50%;
}
.fgb-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 2%;
  padding: 16px;
  border: solid 1px #dadce0;
  border-radius: 2px;
  transition: box-shadow 0.3s linear;
}
.fgb-card:hover {
  box-shadow: 2px 2px 2px 2px #bec2c9;
}
.fgb-card_size_18 {
  width: 18%;
}
.fgb-card_size_24 {
  width: 24%;
}
.fgb-card_size_32 {
  width: 32%;
}
.fgb-card_blue {
  border: solid 1px #3fb2e6;
}
.fgb-card_blue .fgb-card__title {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
  color: #3fb2e6;
  border-radius: 2px;
}
.fgb-card_blue .fgb-card__title::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3fb2e6;
  transition: all 0.5s linear;
}
.fgb-card_blue .fgb-card__title:hover {
  color: white;
  background: rgba(63, 178, 230, 0.75);
}
.fgb-card_blue .fgb-card__title:hover::after {
  left: -50%;
}
.fgb-card_blue .fgb-card__author {
  color: #878d9a;
}
.fgb-card_green {
  border: solid 1px #3aaa35;
}
.fgb-card_green .fgb-card__title {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
  color: #3aaa35;
  border-radius: 2px;
}
.fgb-card_green .fgb-card__title::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3aaa35;
  transition: all 0.5s linear;
}
.fgb-card_green .fgb-card__title:hover {
  color: white;
  background: rgba(58, 170, 53, 0.75);
}
.fgb-card_green .fgb-card__title:hover::after {
  left: -50%;
}
.fgb-card_green .fgb-card__author {
  color: #878d9a;
}
.fgb-card_red {
  border: solid 1px #e41f1e;
}
.fgb-card_red .fgb-card__title {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
  color: #e41f1e;
  border-radius: 2px;
}
.fgb-card_red .fgb-card__title::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #e41f1e;
  transition: all 0.5s linear;
}
.fgb-card_red .fgb-card__title:hover {
  color: white;
  background: rgba(228, 31, 30, 0.75);
}
.fgb-card_red .fgb-card__title:hover::after {
  left: -50%;
}
.fgb-card_red .fgb-card__author {
  color: #878d9a;
}
.fgb-card__img {
  width: 100%;
  border-radius: 2px;
  cursor: pointer;
}
.fgb-card__title {
  margin-top: 8px;
  padding: 8px 16px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  transition: color, background-color 0.5s linear;
}
.fgb-card__info {
  line-height: 1.5;
}
.fgb-card__second-description {
  color: #878d9a;
}
@media screen and (max-width: 810px) {
  .fgb-card_size_18 {
    width: 24%;
  }
  .fgb-card_size_24 {
    width: 32%;
  }
  .fgb-card_size_32 {
    width: 49%;
  }
}
@media screen and (max-width: 430px) {
  .fgb-card_size_18 {
    width: 200px;
  }
  .fgb-card_size_24 {
    width: 260px;
  }
  .fgb-card_size_32 {
    width: 320px;
  }
}
