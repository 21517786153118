.header {
  display: flex;
  align-items: center;
}
.header__logo {
  width: 80px;
}
@media screen and (max-width: 810px) {
  .header__title {
    font-size: 1rem;
  }
  .header__logo {
    width: 40px;
  }
}
