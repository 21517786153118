@import '../../css/colors';
@import '../../css/sizes';

.tournament {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    gap: 24px;

    width: 100%;
    padding: 0 16px;
  }

  &__city-logo {
    width: 72px;
    height: 72px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    text-align: left;
  }

  &__author {color: darken(@grey, 30%)}
}