@import 'colors';
@import 'sizes';

.table {
  min-width: 80%;

  &_blue {.table-mixin(@blue)}
  &_green {.table-mixin(@green)}
  &_red {.table-mixin(@red)}
  &_yellow {.table-mixin(@yellow)}

  &_size-50 {min-width: 50%}

  th {font-weight: 600}

  &, tr, th, td {
    border: solid 1px @grey;
    border-collapse: collapse;
    border-spacing: 0;
  }

  tbody tr {
    &:nth-child(2n + 1) {background-color: lighten(@grey, 12%)}
  }

  &__cell {
    padding: 8px 16px;

    &_left {
      padding-left: 16px;
      text-align: left;
    }
    &_green {
      background-color: @green;
      color: white;
    }
    &_red {
      background-color: @red;
      color: white;
    }
  }

  &__caption {
    padding-bottom: 16px;
    font-weight: 600;
  }

  @media screen and (max-width: @desktop-width) {
    min-width: fit-content;
  }

  @media screen and (max-width: @mobile-width) {
    width: 80%;
    border: none;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 0px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 0px;
    }

    tr {
      display: block;
      border: none;
      margin-bottom: 16px
    }

    td {
      display: block;
      text-align: right;
      padding-right: 16px;
      border: none;
      border-bottom: solid 1px @grey;
    }

    &__cell {
      padding: 0;

      &_left {
        padding-left: 0;
      }
    }

    td::before {
      content: attr(data-label);
      float: left;
      padding-left: 16px;
    }

    .country {
      padding: 0;
      margin-left: auto;

      &__empty-text {
        &::after {
          content: '.';
          margin-right: -12px;
          color: white;
        }
      }
    }

    .flex-row {
      width: fit-content;
      margin-left: auto;

      .country {margin-left: 8px}
    }

    td:last-child {border-bottom: 0}
  }
}

.table-mixin(@color) {
  &, tr, th, td {border: solid 1px @color}
  tbody tr {
    &:nth-child(2n + 1) {background-color: lighten(@color, 40%)}
  }
}

