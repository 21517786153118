.tournament {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
}
.tournament__header {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding: 0 16px;
}
.tournament__city-logo {
  width: 72px;
  height: 72px;
}
.tournament__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}
.tournament__author {
  color: #878d9a;
}
