@import '../../css/colors';

.fgb-menu-sub-item {
  position: relative;

  &__list {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 2;

    width: max-content;

    background-color: white;
    border-radius: 2px;
    box-shadow: 1px 1px 1px 1px @grey;

    list-style: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s linear;
  }

  &:hover > .fgb-menu-sub-item__list {
    opacity: 1;
    visibility: visible;
  }
}