@import '../../css/colors';
@import '../../css/sizes';
@import '../../css/wave-background';

.fgb-menu {
  position: relative;

  &__desktop {
    z-index: 1;
    display: flex;

    list-style: none;
    background-color: white;
  }

  &__mobile {
    .wave-background(@blue);

    position: relative;
    display: none;
    padding: 16px 24px;

    border-radius: 2px;
    font-size: 1.2rem;
    cursor: pointer;
  }

  @media screen and (max-width: @ipad-adaptive-width) {
    width: fit-content;
    margin: 0 auto;

    &__desktop {
      position: absolute;
      flex-direction: column;
    }

    &__mobile {display: block}
  }

  @media screen and (max-width: @mobile-width) {
    margin: 0;
  }
}