.card-layout {
  display: block;
}
.card-layout__content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2%;
  width: 100%;
}
