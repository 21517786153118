@import '../../../css/colors';

.round-table {
  min-width: 50%;

  &__caption {
    padding-bottom: 16px;
  }

  &__name {
    display: inline-block;
    margin-right: 16px;
    font-weight: 600;
  }

  &__date {
    display: inline-block;
    font-size: 0.9em;
  }
}