.card-layout {
  display: block;

  &__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2%;

    width: 100%;
  }
}