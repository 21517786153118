@import '../../css/colors';
@import '../../css/sizes';

.title-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  margin: 16px 0;

  &__title {
    min-width: 80%;
    font-size: 1.2rem;
    padding: 0.5em 1em;
    border: solid 1px white;

    border-radius: 0.125em;
    background-color: white;
    box-shadow: 1px 1px 1px 1px darken(@grey, 10%);
    color: inherit;
    font-weight: 600;
  }
}