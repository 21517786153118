.flex-row {
  display: flex;
  align-items: center;
  width: 100%;
}
.flex-row_center {
  justify-content: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column_center {
  align-items: center;
}
.flex-column_width-50 {
  width: 50%;
}
.flex_gap-8 {
  gap: 8px;
}
.flex_gap-16 {
  gap: 16px;
}
.flex_gap-32 {
  gap: 32px;
}
