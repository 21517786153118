*, :before, :after { box-sizing: border-box }

* {
  margin: 0;
  padding: 0;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #2a2a2a;
  text-align: center;
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 1130px;
  min-width: 360px;
  min-height: calc(100vh - 32px);
  margin: 16px auto;
  padding: 0 5px;
}

@media screen and (max-width: 1120px) {
  .root {
    width: 100%;
  }
}