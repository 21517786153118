/*
  Фон "Волна"
*/
.bg-wave-blue {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-blue::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3fb2e6;
  transition: all 0.5s linear;
}
.bg-wave-blue:hover {
  color: white;
  background: rgba(63, 178, 230, 0.75);
}
.bg-wave-blue:hover::after {
  left: -50%;
}
.bg-wave-green {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-green::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3aaa35;
  transition: all 0.5s linear;
}
.bg-wave-green:hover {
  color: white;
  background: rgba(58, 170, 53, 0.75);
}
.bg-wave-green:hover::after {
  left: -50%;
}
.bg-wave-red {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-red::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #e41f1e;
  transition: all 0.5s linear;
}
.bg-wave-red:hover {
  color: white;
  background: rgba(228, 31, 30, 0.75);
}
.bg-wave-red:hover::after {
  left: -50%;
}
.bg-wave-yellow {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-yellow::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #ffde00;
  transition: all 0.5s linear;
}
.bg-wave-yellow:hover {
  color: white;
  background: rgba(255, 222, 0, 0.75);
}
.bg-wave-yellow:hover::after {
  left: -50%;
}
.fgb-menu-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  border-radius: 2px;
  text-decoration: none;
}
.fgb-menu-link_item {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
  padding: 16px 24px;
  font-size: 1.2rem;
}
.fgb-menu-link_item::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #e41f1e;
  transition: all 0.5s linear;
}
.fgb-menu-link_item:hover {
  color: white;
  background: rgba(228, 31, 30, 0.75);
}
.fgb-menu-link_item:hover::after {
  left: -50%;
}
.fgb-menu-link_item_active {
  background: rgba(228, 31, 30, 0.75);
  color: white;
}
.fgb-menu-link_item_active:after {
  left: -50%;
}
.fgb-menu-link_sub-item {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
  padding: 16px 16px 16px 24px;
  font-size: 1rem;
  text-align: left;
}
.fgb-menu-link_sub-item::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3aaa35;
  transition: all 0.5s linear;
}
.fgb-menu-link_sub-item:hover {
  color: white;
  background: rgba(58, 170, 53, 0.75);
}
.fgb-menu-link_sub-item:hover::after {
  left: -50%;
}
.fgb-text-link {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;
  padding: 0 0.5rem;
  border-radius: 2px;
  color: #3fb2e6;
}
.fgb-text-link::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3fb2e6;
  transition: all 0.5s linear;
}
.fgb-text-link:hover {
  color: white;
  background: rgba(63, 178, 230, 0.75);
}
.fgb-text-link:hover::after {
  left: -50%;
}
.fgb-title-link,
fgb-img-link {
  text-decoration: none;
}
