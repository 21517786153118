.info-board__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  line-height: 1.5;
}
.info-board__item p {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding: 0.5em 0;
}
