@import '../../css/colors';
@import '../../css/sizes';

.book {
  display: flex;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    padding: 16px;

    &:first-child {width: 35%}

    &:last-child {
      width: 70%;
      padding-top: 48px;
    }
  }

  &__img {width: 100%}
  &__author {font-weight: bold}

  @media screen and (max-width: @mobile-width) {
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &__item {
      &:first-child {width: 100%}

      &:last-child {
        width: 100%;
        padding-top: 0;
      }
    }
  }
}