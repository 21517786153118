@import '../../css/sizes';

.news {
  display: flex;
  flex-direction: column;
  gap: @block-items-gap;

  &__description { text-align: left }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}