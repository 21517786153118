@import '../../css/colors';
@import '../../css/sizes';
@import '../../css/wave-background';

.dropdown-layout {
  display: flex;
  flex-direction: column;
  gap: @block-items-gap;

  &__title {
    width: 80%;
    margin: 0 auto;

    box-shadow: 1px 1px 1px 1px darken(@grey, 10%);
  }

  &__content {display: none}
}