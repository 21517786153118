/*
  Фон "Волна"
*/
.bg-wave-blue {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-blue::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3fb2e6;
  transition: all 0.5s linear;
}
.bg-wave-blue:hover {
  color: white;
  background: rgba(63, 178, 230, 0.75);
}
.bg-wave-blue:hover::after {
  left: -50%;
}
.bg-wave-green {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-green::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3aaa35;
  transition: all 0.5s linear;
}
.bg-wave-green:hover {
  color: white;
  background: rgba(58, 170, 53, 0.75);
}
.bg-wave-green:hover::after {
  left: -50%;
}
.bg-wave-red {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-red::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #e41f1e;
  transition: all 0.5s linear;
}
.bg-wave-red:hover {
  color: white;
  background: rgba(228, 31, 30, 0.75);
}
.bg-wave-red:hover::after {
  left: -50%;
}
.bg-wave-yellow {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-yellow::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #ffde00;
  transition: all 0.5s linear;
}
.bg-wave-yellow:hover {
  color: white;
  background: rgba(255, 222, 0, 0.75);
}
.bg-wave-yellow:hover::after {
  left: -50%;
}
.fgb-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  padding: 0.5em 1em;
  border: solid 1px white;
  font-size: 1em;
  border-radius: 0.125em;
  background-color: white;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s linear;
}
.fgb-button_blue {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.fgb-button_blue::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3fb2e6;
  transition: all 0.5s linear;
}
.fgb-button_blue:hover {
  color: white;
  background: rgba(63, 178, 230, 0.75);
}
.fgb-button_blue:hover::after {
  left: -50%;
}
.fgb-button_green {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.fgb-button_green::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3aaa35;
  transition: all 0.5s linear;
}
.fgb-button_green:hover {
  color: white;
  background: rgba(58, 170, 53, 0.75);
}
.fgb-button_green:hover::after {
  left: -50%;
}
.fgb-button_red {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.fgb-button_red::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #e41f1e;
  transition: all 0.5s linear;
}
.fgb-button_red:hover {
  color: white;
  background: rgba(228, 31, 30, 0.75);
}
.fgb-button_red:hover::after {
  left: -50%;
}
.fgb-button_yellow {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.fgb-button_yellow::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #ffde00;
  transition: all 0.5s linear;
}
.fgb-button_yellow:hover {
  color: white;
  background: rgba(255, 222, 0, 0.75);
}
.fgb-button_yellow:hover::after {
  left: -50%;
}
