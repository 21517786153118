@import '../../css/sizes';

.header {
  display: flex;
  align-items: center;

  &__logo {width: 80px}

  @media screen and (max-width: @ipad-adaptive-width) {
    &__title {font-size: 1rem}
    &__logo {width: 40px}
  }
}