.country {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;

  width: fit-content;
  padding: 0 1em;

  &__img {
    max-width: 1.5em;
    height: 1em;
  }

  &__empty-text {}
}