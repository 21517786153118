/*
  Фон "Волна"
*/
.bg-wave-blue {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-blue::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3fb2e6;
  transition: all 0.5s linear;
}
.bg-wave-blue:hover {
  color: white;
  background: rgba(63, 178, 230, 0.75);
}
.bg-wave-blue:hover::after {
  left: -50%;
}
.bg-wave-green {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-green::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3aaa35;
  transition: all 0.5s linear;
}
.bg-wave-green:hover {
  color: white;
  background: rgba(58, 170, 53, 0.75);
}
.bg-wave-green:hover::after {
  left: -50%;
}
.bg-wave-red {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-red::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #e41f1e;
  transition: all 0.5s linear;
}
.bg-wave-red:hover {
  color: white;
  background: rgba(228, 31, 30, 0.75);
}
.bg-wave-red:hover::after {
  left: -50%;
}
.bg-wave-yellow {
  position: relative;
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
}
.bg-wave-yellow::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #ffde00;
  transition: all 0.5s linear;
}
.bg-wave-yellow:hover {
  color: white;
  background: rgba(255, 222, 0, 0.75);
}
.bg-wave-yellow:hover::after {
  left: -50%;
}
.fgb-menu {
  position: relative;
}
.fgb-menu__desktop {
  z-index: 1;
  display: flex;
  list-style: none;
  background-color: white;
}
.fgb-menu__mobile {
  z-index: 0;
  overflow: hidden;
  color: #2a2a2a;
  position: relative;
  display: none;
  padding: 16px 24px;
  border-radius: 2px;
  font-size: 1.2rem;
  cursor: pointer;
}
.fgb-menu__mobile::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0px;
  left: -150%;
  display: inline-block;
  width: 150%;
  height: 250%;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background: #3fb2e6;
  transition: all 0.5s linear;
}
.fgb-menu__mobile:hover {
  color: white;
  background: rgba(63, 178, 230, 0.75);
}
.fgb-menu__mobile:hover::after {
  left: -50%;
}
@media screen and (max-width: 810px) {
  .fgb-menu {
    width: fit-content;
    margin: 0 auto;
  }
  .fgb-menu__desktop {
    position: absolute;
    flex-direction: column;
  }
  .fgb-menu__mobile {
    display: block;
  }
}
@media screen and (max-width: 430px) {
  .fgb-menu {
    margin: 0;
  }
}
