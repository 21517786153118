@import '../../../css/colors';
@import '../../../css/sizes';

.group-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 100%;

  .table {
    tbody tr:first-child {
      background-color: @green;
    }

    tbody tr:nth-child(2) {
      background-color: fade(@green, 70%);
    }

    tbody tr:last-child {
      background-color: @red;
    }

    tbody tr:nth-last-child(2) {
      background-color: fade(@red, 70%);
    }
  }
}