@import '../../css/colors';
@import '../../css/sizes';
@import '../../css/wave-background';

.fgb-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  margin-bottom: 2%;
  padding: 16px;
  border: solid 1px @grey;

  border-radius: 2px;
  transition: box-shadow .3s linear;

  &:hover {box-shadow: 2px 2px 2px 2px darken(@grey, 10%)}

  &_size_18 {width: 18%}
  &_size_24 {width: 24%}
  &_size_32 {width: 32%}

  &_blue {.fgb-card-mixin_color(@blue)}
  &_green {.fgb-card-mixin_color(@green)}
  &_red {.fgb-card-mixin_color(@red)}

  &__img {
    width: 100%;
    border-radius: 2px;

    cursor: pointer;
  }

  &__title {
    margin-top: 8px;
    padding: 8px 16px;
    font-size: 16px;

    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    transition: color, background-color .5s linear;
  }

  &__info {line-height: 1.5}
  &__second-description {color: darken(@grey, 30%)}

  @media screen and (max-width: @ipad-adaptive-width) {
    &_size_18 {width: 24%}
    &_size_24 {width: 32%}
    &_size_32 {width: 49%}
  }

  @media screen and (max-width: @mobile-width) {
    &_size_18 {width: 200px}
    &_size_24 {width: 260px}
    &_size_32 {width: 320px}
  }
}

.fgb-card-mixin_color(@color) {
  border: solid 1px @color;

  .fgb-card__title {
    .wave-background(@color);

    color: @color;
    border-radius: 2px;
  }

  .fgb-card__author {color: darken(@grey, 30%)}
}