.group-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.group-table .table tbody tr:first-child {
  background-color: #3aaa35;
}
.group-table .table tbody tr:nth-child(2) {
  background-color: rgba(58, 170, 53, 0.7);
}
.group-table .table tbody tr:last-child {
  background-color: #e41f1e;
}
.group-table .table tbody tr:nth-last-child(2) {
  background-color: rgba(228, 31, 30, 0.7);
}
