.round-table {
  min-width: 50%;
}
.round-table__caption {
  padding-bottom: 16px;
}
.round-table__name {
  display: inline-block;
  margin-right: 16px;
  font-weight: 600;
}
.round-table__date {
  display: inline-block;
  font-size: 0.9em;
}
