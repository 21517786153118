.footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
.footer__logo {
  width: 100px;
}
@media screen and (max-width: 430px) {
  .footer {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}
