.fgb-menu-sub-item {
  position: relative;
}
.fgb-menu-sub-item__list {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 2;
  width: max-content;
  background-color: white;
  border-radius: 2px;
  box-shadow: 1px 1px 1px 1px #dadce0;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s linear;
}
.fgb-menu-sub-item:hover > .fgb-menu-sub-item__list {
  opacity: 1;
  visibility: visible;
}
