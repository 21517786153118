.by-tournaments {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.by-tournaments__description {
  text-align: left;
}
.by-tournaments__items {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
