@import '../../css/colors';
@import '../../css/wave-background';

.fgb-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;

  padding: 0.5em 1em;
  border: solid 1px white;
  font-size: 1em;

  border-radius: 0.125em;
  background-color: white;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  cursor: pointer;
  transition: background-color .3s linear;

  &_blue {.wave-background(@blue)}
  &_green {.wave-background(@green)}
  &_red {.wave-background(@red)}
  &_yellow {.wave-background(@yellow)}
}